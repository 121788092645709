import React from 'react';
import {
    FaFacebook,
    FaInstagram
} from 'react-icons/fa';
import Image from 'next/image';

import {useWindowSize} from '../../utils/getWindow';
import {navigation} from '../../utils/navigation';

import {Clock,
    Email,
    FooterContainer,
    FooterLink,
    FooterLinkA,
    FooterLinkAContainer,
    FooterLinkDiv,
    FooterLinkItems,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkTitle,
    FooterWrapper,
    IconContainer,
    Location,
    SocialIconLink,
    SocialIcons,
    SocialLogo,
    SocialMedia,
    SocialMediaWrapper,
    Telephone,
    WebsiteRights} from './index.style';

const Footer = () => {
    const {width} = useWindowSize();
    const deckstop = !!(width && width > 700);

    return (
        <FooterContainer>
            <FooterWrapper>
                <FooterLinksContainer>
                    <FooterLinksWrapper >
                        <FooterLinkItems>
                            <FooterLinkTitle>Ссылки</FooterLinkTitle>
                            {navigation.map((el) => (
                                <FooterLink
                                    href={el.to}
                                    key={el.to}
                                    to={el.to}
                                    smooth={true}
                                    duration={500}
                                    spy={true}
                                    offset={-70}
                                >
                                    {el.name}
                                </FooterLink>
                            ))}
                        </FooterLinkItems>
                        <FooterLinkItems>
                            <FooterLinkTitle>Наше местоположение</FooterLinkTitle>
                            <FooterLinkA
                                href="https://www.google.com/maps/place/%D0%93%D0%A0%D0%90%D0%A4%D0%98%D0%A2+-+%D0%91%D0%AE%D0%A0%D0%9E+%D0%9F%D0%9E+%D0%94%D0%98%D0%97%D0%90%D0%99%D0%9D%D0%A3+%D0%98%D0%9D%D0%A2%D0%95%D0%A0%D0%AC%D0%95%D0%A0%D0%90/@53.1331756,29.2229112,19z/data=!3m1!4b1!4m6!3m5!1s0x46d731505246fb7b:0xda532ddd975ce700!8m2!3d53.1331748!4d29.2234584!16s%2Fg%2F11ss9qtblm"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Image
                                    src="/assets/main/7.webp"
                                    alt="location"
                                    width={200}
                                    height={150}
                                />
                            </FooterLinkA>
                        </FooterLinkItems>

                        <FooterLinkItems style={{marginLeft: deckstop ? '60px' : '0px'}}>
                            <FooterLinkTitle>Свяжитесь с нами</FooterLinkTitle>
                            <IconContainer>
                                <Telephone />
                                <FooterLinkAContainer>
                                    <FooterLinkA href="tel:+375 29 656 51 68">
                                        +375 29 656 51 68
                                    </FooterLinkA>
                                    <FooterLinkA href="tel:+375 29 636 98 04">
                                        +375 29 636 98 04
                                    </FooterLinkA>
                                </FooterLinkAContainer>
                            </IconContainer>
                            <IconContainer>
                                <Email />
                                <FooterLinkAContainer>
                                    <FooterLinkA href="mailto:grafit2001@gmail.com">
                                        grafit2001@gmail.com
                                    </FooterLinkA>
                                </FooterLinkAContainer>
                            </IconContainer>
                            <IconContainer>
                                <Location />
                                <FooterLinkA
                                    href="https://www.google.com/maps/place/%D0%93%D0%A0%D0%90%D0%A4%D0%98%D0%A2+-+%D0%91%D0%AE%D0%A0%D0%9E+%D0%9F%D0%9E+%D0%94%D0%98%D0%97%D0%90%D0%99%D0%9D%D0%A3+%D0%98%D0%9D%D0%A2%D0%95%D0%A0%D0%AC%D0%95%D0%A0%D0%90/@53.1331756,29.2229112,19z/data=!3m1!4b1!4m6!3m5!1s0x46d731505246fb7b:0xda532ddd975ce700!8m2!3d53.1331748!4d29.2234584!16s%2Fg%2F11ss9qtblm"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    ул. К. Маркса, 78/122, г. Бобруйск, Беларусь
                                </FooterLinkA>
                            </IconContainer>
                            <IconContainer>
                                <Clock />
                                <FooterLinkDiv>
                                    пн - пт, 9.00 - 18.00
                                </FooterLinkDiv>
                            </IconContainer>
                        </FooterLinkItems>
                    </FooterLinksWrapper>
                </FooterLinksContainer>

                <SocialMedia >
                    <SocialMediaWrapper >
                        <SocialLogo
                            to="home"
                            smooth={true}
                            duration={500}
                            spy={true}
                            offset={-70}
                            href="home"
                        >
                            <Image src={'/assets/main/8.webp'} alt={'logo_footer'} width={150} height={57}/>
                        </SocialLogo>
                        <WebsiteRights >
              Grafit © 2001 - {new Date().getFullYear()} All rights reserved.
                        </WebsiteRights>
                        <SocialIcons >
                            <SocialIconLink href="https://www.facebook.com/profile.php?id=100014957381056" target="_blank" aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>
                            <SocialIconLink href="https://www.instagram.com/grafit_2001/?igshid=ZDdkNTZiNTM%3D" target="_blank" aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrapper>
                </SocialMedia>
            </FooterWrapper>
        </FooterContainer>
    );
};

export default Footer;
