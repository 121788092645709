import {BsFillTelephoneFill} from 'react-icons/bs';
import {IoMdMail} from 'react-icons/io';
import {IoMdClock} from 'react-icons/io';
import {MdLocationOn} from 'react-icons/md';
import {Link as LinkS} from 'react-scroll';
import styled from 'styled-components';

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
`;

export const Telephone = styled(BsFillTelephoneFill)`
  color: white;
  margin-right: 10px;
  margin-top: 5px;
`;

export const Email = styled(IoMdMail)`
  color: white;
  margin-right: 10px;
  margin-top: 5px;
`;
export const Clock = styled(IoMdClock)`
  color: white;
  margin-right: 10px;
  margin-top: 3px;
`;

export const Location = styled(MdLocationOn)`
  color: white;
  margin-right: 10px;
  width: 30px;
  height: 25px;
  @media screen and (max-width: 820px) {
    width: 15px;
    height: 20px;
  }
`;

export const FooterContainer = styled.footer`
  background-color: black;
`;

export const FooterWrapper = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const FooterLinksWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  @media screen and (max-width: 620px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block: 16px;
  text-align: left;
  box-sizing: border-box;
  color: #fff;
  width: 200px;
  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 16px;
  margin-bottom: 16px;
  letter-spacing: 1px;
`;

export const FooterLink = styled(LinkS)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 16px;
  letter-spacing: 0.2px;

  &:hover {
    color: #009fbc;
    transition: 0.3s ease-out;
  }
`;

export const FooterLinkAContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FooterLinkA = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  margin-bottom: 0.5rem;

  &:hover {
    color: #009fbc;
    transition: 0.3s ease-out;
  }
`;
export const FooterLinkDiv = styled.div`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 0.5rem;

  &:hover {
    color: #009fbc;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(LinkS)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 200px;
  @media screen and (max-width: 820px) {
    justify-content: center;
  }
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
